/* istanbul ignore file */
import { useEffect, useState } from 'react';
import { useFormApi } from 'informed';
import { t } from 'i18next';
import { Dropdown, TextField } from '@ui-modules/informed';
import { notEmptyValidator } from '@common/utils';
import { DriveFormSteps, DropdownOption, EventMoved, ShuttleStop } from '@common/interfaces';
import '../styles/modal.scss';

interface FieldProps {
  choices?: { [x: string]: DropdownOption[] };
  eventMoved?: EventMoved;
  fieldGroup?: string;
  index?: number;
  isEdit?: boolean;
  options?: DropdownOption[];
  returnTrip?: boolean;
  scheduled?: ShuttleStop[];
}

const PickupLocationField = ({
  choices,
  eventMoved,
  fieldGroup,
  index,
  isEdit,
  options,
  returnTrip = false,
  scheduled,
}: FieldProps) => {
  const { getFormState, getValue, setValue } = useFormApi();
  const formState = getFormState().values as any;
  const drives = formState?.[DriveFormSteps.Trip]?.driveList;
  const dropoffLoc = drives?.[index!]?.dropoffLocation ?? null;
  const pickupLocationField = fieldGroup ? `${fieldGroup}.pickupLocation` : 'pickupLocation';
  const pickupTownField = fieldGroup ? `${fieldGroup}.pickupTown` : 'pickupTown';
  const pickupTownValue = getValue(pickupTownField) as DropdownOption;

  const [data, setData] = useState<DropdownOption[]>([]);

  useEffect(() => {
    if (returnTrip && dropoffLoc) {
      setValue(pickupLocationField, dropoffLoc);
    }
  }, [dropoffLoc, pickupLocationField, returnTrip, setValue]);

  useEffect(() => {
    let dropdown: DropdownOption[] = [];

    if (options) {
      dropdown = options.slice(0, -1);
    } else if (choices && pickupTownValue && pickupTownValue.value in choices) {
      dropdown = choices[pickupTownValue.value];
    }

    setData(dropdown);

    if (isEdit && drives && !options?.length) {
      const currentLocation = drives?.[index!]?.pickupLocation;
      const formatLocation =
        typeof drives?.[index!]?.pickupLocation === 'object'
          ? (currentLocation as DropdownOption)?.value
          : currentLocation;

      setValue(pickupLocationField, formatLocation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choices, options, pickupTownValue]);

  return (
    <div className="field" data-testid="planner-drive-form-field-pickup-location">
      {choices || options?.length ? (
        <Dropdown
          className="dropdown"
          isClearable
          isCreatable={!!choices?.length || isEdit}
          label={t('bookingDetails.pickupLocation')}
          name="pickupLocation"
          options={data}
          placeholder={
            options ? undefined : `${t('common.select')} ${t('bookingDetails.pickupTown')}...`
          }
          validate={notEmptyValidator}
          onChange={(option) => {
            if (scheduled) {
              const town = scheduled.find((i) => i.location === option?.value)?.town;
              setValue(pickupTownField, town);
            }
          }}
        />
      ) : (
        <TextField
          className="input"
          data-testid="planner-drive-form-field-pickup-location"
          label={t('bookingDetails.pickupLocation')}
          name="pickupLocation"
          validate={notEmptyValidator}
        />
      )}
      {eventMoved === EventMoved.InShuttle && (
        <span className="field-description dropdown">
          Please confirm the correct pick-up location
        </span>
      )}
    </div>
  );
};

export default PickupLocationField;
