import { AxiosInstance } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import fileDownload from 'js-file-download';

import { SessionStorageKeys } from '@common/constants';
import { ApiList } from '@common/interfaces';
import { LBDriveDetails, LBDrivesApi, LBFilterRes } from '@common/interfaces/LogbookAPI';
import { ILogbookRepository, LBParams } from '@common/interfaces/LogbookRepository';
import AxiosRepository from './axios.repository';

class LogbookRepository extends AxiosRepository implements ILogbookRepository {
  public constructor(axiosClient: AxiosInstance) {
    super(axiosClient);
  }

  private buildParams(params?: LBParams) {
    const searchParams = new URLSearchParams();

    if (params?.dateStart) searchParams.append('from_date', params.dateStart);
    if (params?.dateEnd) searchParams.append('to_date', params.dateEnd);
    if (params?.isMultiAgency)
      searchParams.append('agency', sessionStorage.getItem(SessionStorageKeys.AGENCY_ID)!);
    if (typeof params?.isTodoDrives === 'boolean')
      searchParams.append('to_do_drives', String(params?.isTodoDrives));
    if (params?.location) searchParams.append('location', params.location);
    if (params?.ordering) searchParams.append('ordering', params.ordering);
    if (params?.page) searchParams.append('page', String(params.page));
    if (params?.pageSize) searchParams.append('per_page', String(params.pageSize));
    if (params?.search) searchParams.append('search', params.search);
    if (params?.budgetCode) searchParams.append('budget_code', params.budgetCode);
    if (params?.refCode) searchParams.append('reference_code', params.refCode);

    if (params?.auxFilters && Object.keys(params?.auxFilters).length) {
      Object.entries(params?.auxFilters).forEach(([key, value]) => {
        searchParams.append(key, String(value));
      });
    }

    return searchParams;
  }

  private transformData(data: Record<string, any>): any {
    return camelcaseKeys(data, { deep: true });
  }

  public async getDriveById(facility: string, id: string): Promise<LBDriveDetails> {
    const { data } = await this.get(`facilities/${facility}/mobility/logbook/drives/${id}/`);
    return this.transformData(data);
  }

  public async getDrives(facility: string, params?: LBParams): Promise<ApiList<LBDrivesApi>> {
    const { data } = await this.get(`facilities/${facility}/mobility/logbook/drives/`, {
      params: this.buildParams(params),
    });
    return data;
  }

  public async getDrivesExport(facility: string, params?: LBParams) {
    const { data, headers } = await this.get(
      `facilities/${facility}/mobility/logbook/drives/export/`,
      { params: this.buildParams(params), responseType: 'blob' },
    );

    const filename = headers['content-disposition']
      ? headers['content-disposition'].split('filename=')[1].split(';')[0]
      : '';

    fileDownload(new File([data], filename), filename);

    return data;
  }

  public async getFiltersData(facility: string, params?: LBParams): Promise<LBFilterRes> {
    const { data } = await this.get(`facilities/${facility}/mobility/logbook/drives/filter-data/`, {
      params: this.buildParams(params),
    });
    return data;
  }
}

export default LogbookRepository;
