import type { TFunction as TFunc } from 'react-i18next';
import { CountryReport } from '@common/interfaces';
import { formatSecToHours } from '@common/utils';

export const getReportColumns = (t: TFunc): Record<string, any>[] => [
  {
    dataIndex: 'locationName',
    key: 'locationName',
    title: '',
  },
  {
    dataIndex: 'vehicles',
    key: 'vehicles',
    title: t('report.fleet'),
  },
  {
    dataIndex: 'drivers',
    key: 'drivers',
    title: t('common.drivers'),
  },
  {
    dataIndex: 'drives',
    key: 'drives',
    title: t('common.trips'),
  },
  {
    dataIndex: 'drivesElectric',
    key: 'drivesElectric',
    title: t('common.tripsEV'),
  },
  {
    dataIndex: 'drivesDieselPetrol',
    key: 'drivesDieselPetrol',
    title: t('common.tripsDieselPetrol'),
  },
  {
    dataIndex: 'bookingsCount',
    key: 'bookingsCount',
    title: t('crumbs.bookings'),
  },
  {
    dataIndex: 'pax',
    key: 'pax',
    title: t('common.passengers'),
  },
  {
    key: 'agencyPax',
    title: t('report.agencyPaxVsNot'),
    render: (r: CountryReport) => (
      <span>{r.pax && r.agencyPax ? Math.trunc((r.agencyPax / r.pax) * 100) : 'n.a.'}%</span>
    ),
  },
  {
    key: 'airportDrives',
    title: t('common.airport'),
    render: (r: CountryReport) => (
      <span>
        {r.drives && r.airportDrives ? Math.trunc((r.airportDrives / r.drives) * 100) : 'n.a.'}%
      </span>
    ),
  },
  {
    key: 'inTownDrives',
    title: t('common.inTown'),
    render: (r: CountryReport) => (
      <span>
        {r.drives && r.inTownDrives ? Math.trunc((r.inTownDrives / r.drives) * 100) : 'n.a.'}%
      </span>
    ),
  },
  {
    key: 'outOfTownDrives',
    title: t('common.outOfTown'),
    render: (r: CountryReport) => (
      <span>
        {r.drives && r.outOfTownDrives ? Math.trunc((r.outOfTownDrives / r.drives) * 100) : 'n.a.'}%
      </span>
    ),
  },
  {
    dataIndex: 'carpooledDrives',
    key: 'carpooledDrives',
    title: t('report.carpooledTrip'),
  },
  {
    dataIndex: 'carpooledPax',
    key: 'carpooledPax',
    title: `${t('common.carpooled')} Pax`,
  },
  {
    dataIndex: 'rideSharedDrives',
    key: 'rideSharedDrives',
    title: t('report.ridesharedTrip'),
  },
  {
    dataIndex: 'rideSharedPax',
    key: 'rideSharedPax',
    title: `${t('common.rideshared')} pax`,
  },
  {
    dataIndex: 'co2Saving',
    key: 'co2Saving',
    title: `${t('report.co2Saving')} (Kg)`,
  },
  {
    dataIndex: 'efficiencySaving',
    key: 'efficiencySaving',
    title: t('report.efficiencyRidesharing'),
  },
  {
    dataIndex: 'efficiencySavingCarpooling',
    key: 'efficiencySavingCarpooling',
    title: t('report.efficiencyCarpooling'),
  },
  {
    dataIndex: 'totalDrivesKm',
    key: 'totalDrivesKm',
    title: `${t('common.total')} KM`,
  },
  {
    dataIndex: 'totalDrivesTime',
    key: 'totalDrivesTime',
    title: t('report.totalTime'),
    render: (r: number) => <span>{r ? formatSecToHours(r) : '00:00'}</span>,
  },
];
