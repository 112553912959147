/* istanbul ignore file */
import { FC, Fragment, useEffect } from 'react';
import { ArrayField, Input, Multistep, useFormApi, useFormState, useMultistepApi } from 'informed';
import { useTranslation } from 'react-i18next';
import { addSeconds } from 'date-fns';

import {
  Drive,
  DriveFormSteps as Steps,
  DriveFormState,
  PlDrive,
  PlDriveFormData,
  PlVehicle,
  StepOptions,
} from '@common/interfaces';
import { GoogleSuggestion } from '@common/types';
import { calcSharedDrive } from '@common/utils';
import { Button } from '@components';

import { ArrowLeft } from '@assets/svg/icons';
import { useFacility } from '@common/hooks';
import RecurDrive from './RecurDrive';
import {
  AddressField,
  ButtonSubmit,
  CommentField,
  DriverField,
  DropoffDateField,
  DropoffLocationField,
  DropoffTimeField,
  DropoffTownField,
  GoogleLocationField,
  PickupDateField,
  PickupLocationField,
  PickupTimeField,
  PickupTownField,
  VehicleField,
} from '../../../fields';
import '../../../styles/modal.scss';

interface StepProps {
  drive?: Drive;
  drives: PlDrive[];
  formData: PlDriveFormData | null;
  options: StepOptions;
  showGoogleLocation: boolean;
  vehicles: PlVehicle[];
}

const ReturnTripStep: FC<StepProps> = ({
  drive,
  drives,
  formData,
  options,
  showGoogleLocation,
  vehicles,
}) => {
  const { t } = useTranslation();
  const { facility } = useFacility();
  const { city, country, countryCode } = facility;
  const { values } = useFormState() as any;
  const { getCurrentStep, setCurrent } = useMultistepApi();
  const { setValue } = useFormApi();

  const { driver } = drive! || {};
  const { driverOpt, vehicleOpt } = options;

  const isEditProcess = Boolean(formData?.id);
  const fieldGroup = 'driveList';
  const stepReturnActive = getCurrentStep() === Steps.ReturnTrip;
  const tripState = (values as DriveFormState)?.[Steps.Trip]?.[fieldGroup]?.[0];
  const returnTripState = (values as DriveFormState)?.[Steps.ReturnTrip]?.[fieldGroup]?.[0];

  const dropoffDateReturn = returnTripState?.dropoffDate!;
  const dropoffTimeReturn = returnTripState?.dropoffTime!;
  const pickupDateReturn = returnTripState?.pickupDate!;
  const pickupTimeReturn = returnTripState?.pickupTime!;
  const vehicleReturn = returnTripState?.vehicle!;

  const sharedDrive =
    dropoffDateReturn && dropoffTimeReturn && pickupDateReturn && pickupTimeReturn && vehicleReturn
      ? calcSharedDrive({
          drives,
          dropoffDate: dropoffDateReturn,
          dropoffTime: dropoffTimeReturn,
          pickupDate: pickupDateReturn,
          pickupTime: pickupTimeReturn,
          vehicle: vehicleReturn,
        })
      : null;

  useEffect(() => {
    const {
      dropoffLat,
      dropoffLng,
      dropoffLocation,
      dropoffTime,
      dropoffTown,
      estimated,
      pickupLat,
      pickupLng,
      pickupLocation,
      pickupTown,
    } = tripState || {};

    if (dropoffLocation && pickupLocation) {
      const field = `${Steps.ReturnTrip}.driveList.0`;

      setValue(`${field}.pickupLocation`, dropoffLocation);
      setValue(`${field}.pickupLat`, dropoffLat);
      setValue(`${field}.pickupLng`, dropoffLng);
      setValue(`${field}.pickupTown`, dropoffTown);

      setValue(`${field}.dropoffLocation`, pickupLocation);
      setValue(`${field}.dropoffLat`, pickupLat);
      setValue(`${field}.dropoffLng`, pickupLng);
      setValue(`${field}.dropoffTown`, pickupTown);

      if (dropoffTime && estimated?.duration) {
        setValue(`${field}.dropoffTime`, addSeconds(dropoffTime, estimated.duration));
        setValue(`${field}.estimated`, estimated);
      }
    }
  }, [setValue, tripState, stepReturnActive]);

  const handleLocationChange = (name: string) => (e: GoogleSuggestion | string) => {
    if (typeof e === 'object') {
      setValue(`${Steps.ReturnTrip}.${name}AddressRequired`, e.dataset?.isFacilityLocation);
      setValue(`${Steps.ReturnTrip}.${name}Town`, e.dataset?.town || city);
      setValue(`${Steps.ReturnTrip}.${name}Lat`, e.dataset?.lat ?? 0);
      setValue(`${Steps.ReturnTrip}.${name}Lng`, e.dataset?.lng ?? 0);
    }
  };

  return (
    <Multistep.Step step={Steps.ReturnTrip}>
      <nav className="steps">
        <Button
          className="btn"
          data-testid="planner-drive-form-btn-main-info"
          text={t('planner.mainInfo')}
          variant="transparent"
          onClick={() => setCurrent(Steps.Main)}
        />
        <Button
          className="btn"
          data-testid="planner-drive-form-btn-trip-info"
          disabled
          text={t('planner.tripInfo')}
          variant="transparent"
          onClick={() => setCurrent(Steps.Trip)}
        />
      </nav>

      <section className="fieldset">
        <ArrayField name={fieldGroup}>
          {() => (
            <ArrayField.Items>
              {({ index, name }) => (
                <Fragment key={name}>
                  <div className="section-title">2. {t('planner.returnTrip')}</div>

                  <div className="row field-group">
                    {showGoogleLocation && (
                      <>
                        <Input name="dropoffLat" hidden />
                        <Input name="dropoffLng" hidden />
                        <Input name="dropoffTown" hidden />
                        <Input name="pickupLat" hidden />
                        <Input name="pickupLng" hidden />
                        <Input name="pickupTown" hidden />
                        <GoogleLocationField
                          city={city}
                          country={`${country} [${countryCode}]`}
                          fieldGroup={name}
                          idx={index}
                          isReturnTrip
                          label={`${t('bookingDetails.pickupAddress')} (Google Maps)`}
                          name="pickupLocation"
                          required
                          onChange={handleLocationChange(`driveList.${index}.pickup`)}
                        />
                        <GoogleLocationField
                          city={city}
                          country={`${country} [${countryCode}]`}
                          fieldGroup={name}
                          idx={index}
                          isReturnTrip
                          label={`${t('bookingDetails.dropoffAddress')} (Google Maps)`}
                          name="dropoffLocation"
                          required
                          onChange={handleLocationChange(`driveList.${index}.dropoff`)}
                        />
                        <AddressField
                          idx={index}
                          isReturnTrip
                          label="Pick-up Address"
                          name="pickup"
                        />
                        <AddressField
                          idx={index}
                          isReturnTrip
                          label="Drop-off Address"
                          name="dropoff"
                        />
                      </>
                    )}

                    <PickupDateField fieldGroup={name} />
                    <PickupTimeField fieldGroup={name} index={index} returnTrip />

                    {!showGoogleLocation && (
                      <>
                        <PickupTownField fieldGroup={name} returnTrip />
                        <PickupLocationField fieldGroup={name} index={index} returnTrip />
                      </>
                    )}

                    <DropoffDateField />
                    <DropoffTimeField fieldGroup={name} />

                    {!showGoogleLocation && (
                      <>
                        <DropoffTownField fieldGroup={name} returnTrip />
                        <DropoffLocationField fieldGroup={name} index={index} returnTrip />
                      </>
                    )}

                    <VehicleField options={vehicleOpt} />
                    <DriverField
                      driver={driver}
                      options={driverOpt}
                      fieldGroup={name}
                      isEdit={isEditProcess}
                      sharedDrive={isEditProcess ? null : sharedDrive}
                      vehicles={vehicles}
                    />

                    <CommentField label={t('planner.commentPax')} name="commentToPax" />
                    <CommentField label={t('planner.commentDriver')} name="commentToDriver" />
                  </div>
                </Fragment>
              )}
            </ArrayField.Items>
          )}
        </ArrayField>

        {values.addRecurring ? (
          <div className="row">
            <RecurDrive currentDate={formData?.resDate} step={Steps.ReturnTrip} />
          </div>
        ) : null}

        <footer>
          <Button
            className="btn-back"
            data-testid="planner-drive-form-btn-back"
            leftIcon={<ArrowLeft />}
            text={t('common.btnBack')}
            variant="transparent"
            onClick={() => setCurrent(Steps.Trip)}
          />

          <div>
            {values.addRecurring ? (
              <Button
                onClick={() => setValue('addRecurring', false)}
                text={t('common.recurring.cancelRecurringBtn')}
                variant="primary"
              />
            ) : (
              <Button
                onClick={() => setValue('addRecurring', true)}
                text={t('common.recurring.addRecurringBtn')}
                variant="primary"
              />
            )}

            {sharedDrive ? (
              <Button
                data-testid="planner-drive-form-btn-save"
                text={t('common.btnSave')}
                variant="primary"
                onClick={() => setCurrent(Steps.ApprovingSharedDrive)}
              />
            ) : (
              <ButtonSubmit />
            )}
          </div>
        </footer>
      </section>
    </Multistep.Step>
  );
};

export default ReturnTripStep;
