import type { FC } from 'react';
import { AgencyIcons } from '@common/utils';
import { LBDriveDetails } from '@common/interfaces';
import { ExpansionPanel, InfoBlock, InfoBlockType } from '@components/logbook';
import {
  Calendar,
  Clock,
  Driver,
  Passengers,
  TransferType,
  Vehicle,
} from '@assets/svg/icons/logbook';
import './BookingInfo.styles.scss';

interface BookingInfoProps {
  data: LBDriveDetails;
}

const BookingInfo: FC<BookingInfoProps> = ({ data }) => {
  const firstRowStyle = { marginRight: 24, height: 118, flex: 1 };
  const secondRowStyle = { marginRight: 24, height: 89, justifyContent: 'space-between', flex: 1 };
  const thirdRowStyle = { marginRight: 24, height: 88, justifyContent: 'space-between', flex: 1 };
  const Logo = AgencyIcons.icon(data?.vehicle?.agency?.shortName);

  return (
    <ExpansionPanel title="Booking detail" isOpenDefault>
      <div className="details-row" style={{ marginBottom: 16 }}>
        <InfoBlock
          style={{ ...firstRowStyle, marginRight: 0, height: 96 }}
          type={InfoBlockType.title}
          title="Vehicle Info"
        >
          <div className="details-vehicle-title-container">
            <Logo height="29px" width="29px" />
            <div style={{ marginRight: 16 }} />
            <Vehicle />
            <p className="details-vehicle-title">{data.vehicle?.label}</p>
          </div>
        </InfoBlock>
      </div>

      <div className="details-row" style={{ marginBottom: 16 }}>
        <InfoBlock
          style={{ ...secondRowStyle, justifyContent: 'space-between' }}
          type={InfoBlockType.title}
          title="Transfer Type"
        >
          <div className="row-centered">
            <TransferType />
            <p className="details-info-text-row">{data.transferTypeRepr}</p>
          </div>
        </InfoBlock>

        <InfoBlock
          style={{ ...secondRowStyle, justifyContent: 'space-between' }}
          type={InfoBlockType.title}
          title="Trip Type"
        >
          <p>{data?.typeOfTripRepr}</p>
        </InfoBlock>

        <InfoBlock
          style={{ ...secondRowStyle, justifyContent: 'space-between', marginRight: 0 }}
          type={InfoBlockType.title}
          title="Budget Code"
        >
          <p>{data?.budgetCode}</p>
        </InfoBlock>
      </div>

      <div className="details-row" style={{ marginBottom: 16 }}>
        <InfoBlock style={secondRowStyle} type={InfoBlockType.title} title="Pick-up date">
          <div className="row-centered">
            <Calendar />
            <p className="details-info-text-row">{data.pickupDate}</p>
          </div>
        </InfoBlock>

        <InfoBlock style={secondRowStyle} type={InfoBlockType.title} title="Pick-up time">
          <div className="row-centered">
            <Clock />
            <p className="details-info-text-row">{data.pickupTime?.substring(0, 5)}</p>
          </div>
        </InfoBlock>

        <InfoBlock
          style={{ ...secondRowStyle, marginRight: 0 }}
          type={InfoBlockType.title}
          title="Arrival time"
        >
          <div className="row-centered">
            <Clock />
            <p className="details-info-text-row">{data.dropoffTime?.substring(0, 5)}</p>
          </div>
        </InfoBlock>
      </div>

      <div className="details-row" style={{ marginBottom: 16 }}>
        <InfoBlock style={thirdRowStyle} type={InfoBlockType.title} title="Driver name">
          <div className="row-centered">
            <Driver />
            <p className="details-info-text-row">
              {data.driver.firstName} {data.driver.lastName}
            </p>
          </div>
        </InfoBlock>

        <InfoBlock
          style={{ ...thirdRowStyle, marginRight: 0 }}
          type={InfoBlockType.title}
          title="Number of passengers"
        >
          <div className="row-centered">
            <Passengers />
            <p className="details-info-text-row">
              {data.nOfPassengers} {data.nOfPassengers === '1' ? 'passenger' : 'passengers'}
            </p>
          </div>
        </InfoBlock>
      </div>

      <div className="details-row" style={{ marginBottom: 16 }}>
        <InfoBlock
          pickUpLocation={data.pickupLocation}
          dropOffLocation={data.dropoffLocation}
          style={{ ...thirdRowStyle, marginRight: 0 }}
          type={InfoBlockType.location}
        />
      </div>
    </ExpansionPanel>
  );
};

export default BookingInfo;
