/* istanbul ignore file */
import { DropdownOption, SchemaChoiceApi } from './Common';
import { DriveStatus } from './MobilityPlannerApi';

export enum BForwardType {
  Forward = 'forward',
  ForwardByOtherAgency = 'forwarded_by_other_agency',
  Forwarded = 'forwarded',
  NotForForward = 'not_for_forward',
  ProcessedByOriginalAgency = 'processed_by_original_agency',
  ProcessedByOtherAgency = 'processed_by_other_agency',
  Rejected = 'rejected',
  RejectedByAllFP = 'rejected_by_all_fp',
}

export enum BookingTripType {
  OneWay = 'one-way',
  RoundTrip = 'round-trip',
  MultiLeg = 'multi-leg',
}

export enum BulkAssignStatus {
  Ok = 'ok',
  NoAssignment = 'no_assignment',
  MultipleAssignments = 'multiple_assignments',
}

export enum BookingState {
  Accepted = 'accepted',
  Cancelled = 'cancelled',
  CancelledByFP = 'cancelled_by_fp',
  CancelledByUser = 'cancelled_by_user',
  Disabled = 'disabled',
  Finished = 'finished',
  NotProcessed = 'not_processed',
  Ongoing = 'ongoing',
  Rejected = 'rejected',
}

export enum TransferType {
  Airport = 'airport',
  InTown = 'in_town',
  OutTown = 'out_of_town',
  Shuttle = 'shuttle',
}

export declare type BookingRouteReject = {
  agencyId: string;
  bookingId: string;
  rejectComment?: string;
  route: number;
};

export declare type BookingRouteRejectApi = {
  agency_id: string;
  reject_comment: string | null;
};

interface BookingAgencyApi {
  agency_id_on_hbh: number;
  complete_name: string;
  domain: string;
  short_name: string;
  uuid: string;
}

interface BookingAgency {
  agencyId: number;
  completeName: string;
  id: string;
  shortName: string;
}

interface BDAttachmentsApi {
  attachment_link: string;
  is_passport: boolean;
}

export interface BDAttachments {
  attachmentLink: string;
  isPassport: boolean;
}

interface BookingDetailsExtraApi {
  estimated_distance: number;
  estimated_duration: string;
  estimated_duration_in_traffic: string;
}

export interface BookingDetailsExtra {
  estimatedDistance: number;
  estimatedDuration: string;
  estimatedDurationInTraffic: string;
}

interface BookingDetailsPaxInfoApi {
  agency: string;
  email: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  requesting_unit_display: string;
}

interface BookingDetailsPaxInfo {
  agency: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  requestingUnitDisplay: string;
}

export interface BDRouteApi {
  drive_id: string;
  dropoff_date: string;
  dropoff_latitude?: number | null;
  dropoff_location: string;
  dropoff_longitude?: number | null;
  dropoff_time: string;
  dropoff_town: string;
  extra?: BookingDetailsExtraApi | null;
  is_active: boolean;
  pickup_date: string;
  pickup_latitude?: number | null;
  pickup_location: string;
  pickup_longitude?: number | null;
  pickup_time: string;
  pickup_town: string;
  reject_comment?: string | null;
  scheduled_route_id?: string | null;
  signature_code: string;
  state?: BookingState;
  uuid: string;
}

export interface BDRoute {
  driveId: string;
  dropoffDate: string;
  dropoffLatitude?: number | null;
  dropoffLocation: string;
  dropoffLongitude?: number | null;
  dropoffTime: string;
  dropoffTown: string;
  extra?: BookingDetailsExtra | null;
  id: string;
  isActive: boolean;
  pickupDate: string;
  pickupLatitude?: number | null;
  pickupLocation: string;
  pickupLongitude?: number | null;
  pickupTime: string;
  pickupTown: string;
  routeNumber: number;
  rejectComment?: string | null;
  scheduledRouteId?: string | null;
  state?: BookingState;
  signatureCode: string;
}

export interface BookingDetailsApi {
  agency: string;
  agency_fk: BookingAgencyApi | null;
  attachments: BDAttachmentsApi[];
  booking_agent_email: string;
  booking_agent_first_name: string;
  booking_agent_last_name: string;
  booking_agent_username: string;
  budget_code: string;
  dispatch_date: string;
  drives: BookingDriveApi[];
  email: string;
  facility_id: string;
  first_name: string;
  flight_arrival_departure_time: string;
  flight_number: string;
  forwarded: boolean;
  index_number: string;
  last_name: string;
  manager_email: string;
  n_of_passengers: number;
  passengers_info: BookingDetailsPaxInfoApi[];
  phone_number: string;
  purpose: string;
  purpose_display: string;
  recurring: {
    end_date: string;
    frequency_display: string;
    start_date: string;
    weekdays: number[];
    weekdays_display: string;
  } | null;
  reference_code: string;
  remarks: string;
  requesting_unit: string;
  requesting_unit_display: string;
  routes: BDRouteApi[];
  state: BookingState;
  state_display: string;
  transfer_type: TransferType;
  transfer_type_display: string;
  type_of_trip: BookingTripType;
  type_of_trip_display: string;
  uuid: string;
}

export interface BookingDetails {
  agency: string;
  agencyFk: BookingAgency | null;
  attachments: BDAttachments[];
  bookingAgentEmail: string;
  bookingAgentFirstName: string;
  bookingAgentLastName: string;
  bookingAgentUsername: string;
  budgetCode: string;
  dispatchDate: string;
  drives: BDDrive[];
  email: string;
  facilityId: string;
  firstName: string;
  flightArrivalDepartureTime: string;
  flightNumber: string;
  forwarded: boolean;
  id: string;
  indexNumber: string;
  lastName: string;
  managerEmail: string;
  pax: number;
  passengersInfo: BookingDetailsPaxInfo[];
  phoneNumber: string;
  purpose: string;
  purposeDisplay: string;
  recurringData: {
    endDate: string;
    frequencyDisplay: string;
    startDate: string;
    weekdaysDisplay: string;
  } | null;
  refCode: string;
  remarks: string;
  requestingUnit: string;
  requestingUnitDisplay: string;
  routes: BDRoute[];
  state: BookingState;
  stateDisplay: string;
  transferType: TransferType;
  transferTypeDisplay: string;
  typeOfTrip: BookingTripType;
  typeOfTripDisplay: string;
}

export interface BookingDriveApi {
  comment_to_driver: string | null;
  comment_to_pax: string | null;
  driver: string;
  driver_id: string;
  dropoff_date: string;
  dropoff_location: string;
  dropoff_time: string;
  dropoff_town: string;
  is_active: boolean;
  is_carpooled: boolean;
  is_ride_shared: boolean;
  pickup_date: string;
  pickup_location: string;
  pickup_time: string;
  pickup_town: string;
  scheduled_route_id: string | null;
  signature_code: string;
  state: DriveStatus;
  state_display: string;
  uuid: string;
  vehicle: string;
  vehicle_id: string;
}

export interface BDDrive {
  commentToDriver: string;
  commentToPax: string;
  driveNumber: number;
  driver: string;
  driverId: string;
  dropoffDate: string;
  dropoffLocation: string;
  dropoffTime: string;
  dropoffTown: string;
  id: string;
  isActive: boolean;
  isCarpooled: boolean;
  isRideShared: boolean;
  pickupDate: string;
  pickupLocation: string;
  pickupTime: string;
  pickupTown: string;
  scheduledRouteId: string | null;
  signatureCode: string;
  state: DriveStatus;
  stateDisplay: string;
  vehicle: string;
  vehicleId: string;
}

export interface BookingRouteApi {
  booked_route_order: number;
  drive_pk: string;
  driver: string;
  dropoff_date: string;
  dropoff_location: string;
  dropoff_time: string;
  dropoff_town: string;
  pickup_date: string;
  pickup_location: string;
  pickup_time: string;
  pickup_town: string;
  route_state: BookingState;
  signature_code: string;
  state: BookingState;
  state_display: string;
  vehicle: string;
}

export interface BookingRoute {
  bookedRouteOrder: number;
  drivePK: string;
  driver: string;
  dropoffDate: string;
  dropoffLocation: string;
  dropoffTime: string;
  dropoffTown: string;
  pickupDate: string;
  pickupLocation: string;
  pickupTime: string;
  pickupTown: string;
  routeState: BookingState;
  signatureCode: string;
  state: BookingState;
  stateDisplay: string;
  vehicle: string;
}

export interface BookingsApi {
  agency: string | null;
  agency_fk: string;
  agency_short_name: string;
  dispatch_date: string;
  first_name: string;
  forward_key: BForwardType;
  forward_label: string;
  forward_status_per_agency: string[];
  has_attachments: boolean;
  is_recurring: boolean;
  last_name: string;
  number_of_pax: string;
  pickup_date: string;
  pickup_time: string;
  reference_code: string;
  requesting_unit: string;
  routes: BookingRouteApi[];
  state: BookingState;
  transfer_type: TransferType;
  transfer_type_display: string;
  type_of_trip: BookingTripType;
  type_of_trip_display: string;
  uuid: string;
}

export interface Bookings {
  agencyFk: string;
  agencyName: string;
  agencyShortName: string;
  dispatchDate: string;
  firstName: string;
  forwardKey: BForwardType;
  forwardLabel: string;
  forwardStatus: string[];
  hasAttachments: boolean;
  id: string;
  isRecurring: boolean;
  lastName: string;
  pax: string;
  pickupDate: string;
  pickupTime: string;
  refCode: string;
  requestingUnit: string;
  routes: BookingRoute[];
  state: BookingState;
  transferType: TransferType;
  transferTypeDisplay: string;
  typeOfTrip: BookingTripType;
  typeOfTripDisplay: string;
}

export interface BulkAssignRoutesApi {
  driver_id: string;
  driver_label: string;
  order: number;
  vehicle_id: string;
  vehicle_label: string;
}

export interface BulkAssignRoutes {
  driverId: string;
  driverLabel: string;
  order: number;
  vehicleId: string;
  vehicleLabel: string;
}

export interface BulkAssignApi {
  booking_id: string;
  routes: BulkAssignRoutesApi[];
  status: BulkAssignStatus;
}

export interface BulkAssign {
  bookingId: string;
  bulkAssignRoutes: BulkAssignRoutes[];
  status: BulkAssignStatus;
}

export interface BulkAssignBooking extends Bookings, BulkAssign {}

export interface BulkAssignBookingUpdate {
  booking_id: string;
  routes: { driver_id: string; order: number; vehicle_id: string }[];
}

export interface BulkAssignVerifyApi {
  agency: string;
  booking_uuids: string[];
}

export interface IBookingUpdate {
  reject_comment?: string;
  state?: BookingState;
}

export interface IFilters {
  booking_states?: DropdownOption[];
  passenger_agencies?: DropdownOption[];
}

export interface BFSchemaApi {
  [x: string]: {
    children?: {
      [y: string]: {
        choices?: SchemaChoiceApi[];
        child?: {
          children?: {
            [y: string]: {
              choices?: SchemaChoiceApi[];
              help_text?: string;
              label: string;
              max_length?: number;
              read_only: boolean;
              required: boolean;
              type: string;
            };
          };
          choices?: SchemaChoiceApi[];
          read_only: boolean;
          required: boolean;
          type: string;
        };
        help_text?: string;
        label: string;
        max_length?: number;
        max_value?: number;
        min_value?: number;
        read_only: boolean;
        required: boolean;
        type: string;
      };
    };
    label: string;
    read_only: boolean;
    required: boolean;
    type: string;
  };
}

export interface BFShuttleRouteApi {
  end_date: string;
  excluded_dates: string[];
  included_dates: string[];
  label: string;
  locations: {
    dropoff_time: string;
    location: string;
    pickup_time: string;
    town: string;
    uuid: string;
  }[];
  related_schedule_id: string | null;
  route_id: string;
  start_date: string;
  uuid: string;
  weekdays: number[];
}

export interface BFShuttleRoute {
  endDate: Date;
  excludedDates: string[];
  includedDates: string[];
  label: string;
  locations: {
    dropoffTime: string;
    location: string;
    pickupTime: string;
    town: string;
    uuid: string;
  }[];
  relatedScheduleId: string | null;
  routeId: string;
  startDate: Date;
  uuid: string;
  weekdays: number[];
}

export interface BookingCreateApi {
  agent_details?: {
    booking_agent_email: string;
    booking_agent_first_name: string;
    booking_agent_last_name: string;
    booking_agent_username?: string;
  };
  booking_details: {
    agency?: string;
    agency_fk: number;
    attachments: Array<File | string>;
    email: string;
    first_name: string;
    index_number: string;
    last_name: string;
    phone_number: string;
  };
  booking_extra: {
    budget_code?: string;
    flight_arrival_departure_time?: string;
    flight_number?: string;
    manager_email: string;
    n_of_passengers: number;
    purpose: string;
    remarks?: string;
    requesting_unit: string;
  };
  driver_detail: {
    routes: {
      dropoff_date: string;
      dropoff_latitude?: number;
      dropoff_location: string;
      dropoff_longitude?: number;
      dropoff_time: string;
      dropoff_town: string;
      pickup_date: string;
      pickup_latitude?: number;
      pickup_location: string;
      pickup_longitude?: number;
      pickup_time: string;
      pickup_town: string;
      scheduled_route_id?: string;
    }[];
    passenger_infos?: {
      agency: string;
      email: string;
      first_name: string;
      last_name: string;
      phone_number: string;
      requesting_unit: string;
    }[];
    transfer_type: TransferType;
    type_of_trip?: BookingTripType;
  };
  is_booking_on_behalf_of_colleague: boolean;
  recurring?: {
    end_date: string;
    frequency: number;
    start_date: string;
    weekdays?: number[];
  };
}
