import { type FC, useCallback, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { DriverLogo } from '@assets/svg/logos';
import {
  DropdownOption,
  MobilityRequestsType,
  MROptionsApi,
  PrivateVehicleCreateApi,
} from '@common/interfaces';
import { getErrors, getFormSubmitError, Serializer } from '@common/utils';
import { Button, Form, Panel } from '@components';
import { useRepository } from '@context';
import { FormType, PVSerializer } from './utils';
import PrivateVehicleSchema, { PrivateVehicleFormType } from './schema/PrivateVehicleForm.schema';
import { Fieldset, Success } from './components';
import './PrivateVehicleFormPage.styles.scss';

const PrivateVehicleFormPage: FC = () => {
  const { t } = useTranslation();
  const { mobilityRequestsRepo } = useRepository();

  const [options, setOptions] = useState<Record<string, DropdownOption[]> | null>(null);
  const [requestSubmitted, setRequestSubmitted] = useState<boolean>(false);

  const { isLoading: isRequestCreating, mutate: createPrivateVehicle } = useMutation(
    'create-booking',
    (data: PrivateVehicleCreateApi) => mobilityRequestsRepo.createPrivateVehicle(data),
    {
      onSuccess: () => setRequestSubmitted(true),
      onError: (e: any) => {
        toast.error(getErrors(e.response.data));
      },
    },
  );

  const { isLoading: isMobilityOptionsLoading } = useQuery(
    'get-mobility-requests-options',
    () => mobilityRequestsRepo.getMobilityRequestsOptions(),
    {
      onSuccess: (res: MROptionsApi[]) => {
        const data = res.find((i) => i.name === MobilityRequestsType.PRIVATE_VEHICLE);

        if (data) {
          const transOptions = Object.entries(data.options).reduce((acc, [key, values]) => {
            acc[Serializer.formatCamelCase(key)] = values.map((value) => ({ label: value, value }));

            return acc;
          }, {} as Record<string, DropdownOption[]>);

          setOptions(transOptions);
        }
      },
    },
  );

  const handleFormSubmit = useCallback(
    async (data: PrivateVehicleFormType) => {
      const payload = await PVSerializer.mapFormDataToPrivateVehicleCreate(data);

      createPrivateVehicle(payload);
    },
    [createPrivateVehicle],
  );

  const handleFormSubmitError = (error: Record<string, any>) => {
    toast.error(getFormSubmitError(error));
  };

  const schema = useMemo(() => PrivateVehicleSchema(), []);

  const initFormData = useMemo(
    () => ({
      acceptConditions: true,
      agency: '',
      contactEmail: [],
      contractType: '',
      country: '',
      dateFrom: undefined,
      dateTo: undefined,
      dutyStation: '',
      profileEmail: '',
      entryOnDuty: undefined,
      firstName: '',
      formType: FormType.Self,
      isDriverNeeded: false,
      lastName: '',
      notToExceed: undefined,
      position: '',
      preferredTypeOfVehicle: '',
      purpose: '',
      reason: '',
    }),
    [],
  );

  return (
    <section className="page-container private-vehicle-page" data-testid="private-vehicle-form">
      {!requestSubmitted && (
        <Panel
          className="content-panel"
          isLoading={isMobilityOptionsLoading || isRequestCreating}
          title={
            <>
              <DriverLogo /> {t('mobilityRequest.formTitle')}
            </>
          }
        >
          <Form
            className="form-private-vehicle"
            defaultValues={initFormData}
            schema={schema}
            theme="dark"
            onSubmit={handleFormSubmit}
            onError={handleFormSubmitError}
          >
            {({ setValue, watch }) => (
              <>
                <Fieldset options={options} setValue={setValue} watch={watch} />

                <footer>
                  <Button
                    className="btn-submit"
                    disabled={!watch('acceptConditions')}
                    text={t('mobilityRequest.submit')}
                    type="submit"
                  />
                </footer>
              </>
            )}
          </Form>
        </Panel>
      )}

      {requestSubmitted && <Success />}
    </section>
  );
};

export default PrivateVehicleFormPage;
