import { useTranslation } from 'react-i18next';
import { DriverLogo } from '@assets/svg/logos';
import { Button } from '@components';

const UNBH_HOME = process.env.REACT_APP_UNBH_HOME_LINK;

const SuccessSection = () => {
  const { i18n, t } = useTranslation();

  return (
    <section className="form-private-vehicle-success">
      <h1 className="title">
        <DriverLogo /> {t('mobilityRequest.formTitle')}
      </h1>
      <div className="status">
        <h2>{t('bookingForm.msgSuccess')}.</h2>
      </div>

      <footer>
        <Button
          className="btn"
          link={`${UNBH_HOME}/${i18n.language}/un-mobility/`}
          text={t('bookingForm.btnHomePage')}
        />
      </footer>
    </section>
  );
};

export default SuccessSection;
