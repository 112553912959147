import type { ReactNode } from 'react';
import type { LBFilter, LBFilterAction, LBFilterSelected } from '@common/interfaces';
import { FilterActionTypes, FilterDictionary } from '@common/constants';
import { Info } from '@assets/svg/icons';
import { Tag } from '@components';
import './FiltersBlock.styles.scss';

type FiltersBlockProps = {
  aux?: ReactNode;
  filters: LBFilter[];
  open: boolean;
  selected: LBFilterSelected;
  filterDispatch?: (_: LBFilterAction) => void;
  onClear?: () => void;
};

const FiltersBlock = ({
  aux,
  filters,
  open,
  selected,
  filterDispatch,
  onClear,
}: FiltersBlockProps) => {
  const handleFilterChange = (title: string, option: string) => {
    filterDispatch?.({ type: FilterActionTypes.CHANGE_FILTER, payload: { title, option } });
  };

  const handleClear = () => {
    filterDispatch?.({ type: FilterActionTypes.CLEAR_ALL });
    onClear?.();
  };

  const handleCancel = (title: string) => {
    filterDispatch?.({ type: FilterActionTypes.CLEAR_ONE, payload: title });
  };

  return (
    <div>
      {open && (
        <div className="logbook-filters-section">
          <div className="filter-container">
            <div className="filters">
              {filters?.map((item) => (
                <div className="filter" key={item.title}>
                  <select
                    data-testid={FilterDictionary[item.title].toLowerCase()}
                    value={
                      item.selected || FilterDictionary[item.title]?.toUpperCase() || item.title
                    }
                    onChange={(e) => handleFilterChange(item.title, e.target.value)}
                  >
                    <option disabled>
                      {FilterDictionary[item.title]?.toUpperCase() || item.title}
                    </option>
                    {item.options.map((i) => (
                      <option key={i.value} value={i.value}>
                        {i.value}
                      </option>
                    ))}
                  </select>
                </div>
              ))}
            </div>
            <button className="btn-clear" type="button" onClick={handleClear}>
              Clear all filters
            </button>
          </div>

          {selected.length === 0 ? (
            <div className="empty-tags-container" data-testid="empty-tags-container">
              <Info />
              <p className="empty-tags-label">All categories are chosen</p>
            </div>
          ) : (
            <div className="tags-container">
              {selected.map((item) => (
                <div key={item.title}>
                  <Tag
                    title={item.title.replace('_', ' ') || item.title}
                    value={item.selected}
                    onCancel={() => handleCancel(item.title)}
                  />
                </div>
              ))}
            </div>
          )}

          <div className="logbook-filters-aux">{aux}</div>
        </div>
      )}
    </div>
  );
};

export default FiltersBlock;
